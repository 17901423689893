var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Modal",
    {
      staticClass: "tProType",
      attrs: { title: _vm.editTitle, "mask-closable": false },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _c(
        "Form",
        {
          ref: "tProTypeForm",
          attrs: {
            model: _vm.tProTypeForm,
            rules: _vm.tProTypeFormRule,
            "label-width": 100,
          },
        },
        [
          _c(
            "Row",
            [
              _c(
                "Col",
                { attrs: { span: "24" } },
                [
                  _c(
                    "FormItem",
                    { attrs: { label: "类型名称", prop: "typeName" } },
                    [
                      _c("Input", {
                        directives: [
                          {
                            name: "width",
                            rawName: "v-width",
                            value: 330,
                            expression: "330",
                          },
                        ],
                        attrs: {
                          disabled: _vm.disabled,
                          type: "text",
                          placeholder: "请输入类型名称",
                        },
                        model: {
                          value: _vm.tProTypeForm.typeName,
                          callback: function ($$v) {
                            _vm.$set(_vm.tProTypeForm, "typeName", $$v)
                          },
                          expression: "tProTypeForm.typeName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "Col",
                { attrs: { span: "24" } },
                [
                  _c(
                    "FormItem",
                    { attrs: { label: "类型编码", prop: "typeCode" } },
                    [
                      _c("Input", {
                        directives: [
                          {
                            name: "width",
                            rawName: "v-width",
                            value: 330,
                            expression: "330",
                          },
                        ],
                        attrs: {
                          disabled: _vm.disabled,
                          type: "text",
                          maxlength: 50,
                          placeholder: "请输入类型编码",
                        },
                        model: {
                          value: _vm.tProTypeForm.typeCode,
                          callback: function ($$v) {
                            _vm.$set(_vm.tProTypeForm, "typeCode", $$v)
                          },
                          expression: "tProTypeForm.typeCode",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "Col",
                { attrs: { span: "24" } },
                [
                  _c(
                    "FormItem",
                    { attrs: { label: "上级类型" } },
                    [
                      _c("TreeSelect", {
                        directives: [
                          {
                            name: "width",
                            rawName: "v-width",
                            value: 330,
                            expression: "330",
                          },
                        ],
                        attrs: { data: _vm.treeData, disabled: _vm.disabled },
                        model: {
                          value: _vm.tProTypeForm.parentId,
                          callback: function ($$v) {
                            _vm.$set(_vm.tProTypeForm, "parentId", $$v)
                          },
                          expression: "tProTypeForm.parentId",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "Col",
                { attrs: { span: "24" } },
                [
                  _c(
                    "FormItem",
                    { attrs: { label: "排序", prop: "orderNum" } },
                    [
                      _c("InputNumber", {
                        attrs: {
                          disabled: _vm.disabled,
                          min: 0,
                          placeholder: "请输入排序",
                        },
                        model: {
                          value: _vm.tProTypeForm.orderNum,
                          callback: function ($$v) {
                            _vm.$set(_vm.tProTypeForm, "orderNum", $$v)
                          },
                          expression: "tProTypeForm.orderNum",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "Col",
                { attrs: { span: "24" } },
                [
                  _c(
                    "FormItem",
                    { attrs: { label: "备注" } },
                    [
                      _c("Input", {
                        attrs: {
                          disabled: _vm.disabled,
                          type: "textarea",
                          rows: 6,
                          maxlength: 50,
                          placeholder: "请输入备注",
                        },
                        model: {
                          value: _vm.tProTypeForm.remark,
                          callback: function ($$v) {
                            _vm.$set(_vm.tProTypeForm, "remark", $$v)
                          },
                          expression: "tProTypeForm.remark",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "Button",
            {
              attrs: { type: _vm.disabled ? "primary" : "text" },
              on: {
                click: function ($event) {
                  _vm.show = false
                },
              },
            },
            [_vm._v("取消")]
          ),
          !_vm.disabled
            ? _c(
                "Button",
                {
                  attrs: { type: "primary", loading: _vm.loading },
                  on: { click: _vm.handelSubmit },
                },
                [_vm._v("提交")]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }